<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>

<style lang="scss">
@import '@/styles/_var.scss';

#app {
  font-family: $font-text;
  color: $text;
}
</style>
