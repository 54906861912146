<template>
  <div class="container">
    <div class="header">
      <Navbar />
    </div>
    <div class="description">
      <h2>Games</h2>
      <h3>
        <a href="https://github.com/Rupikz/tic-tac-toe"
          >tic-tac-toe<svg
            class="svg-link"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 24 24"
          >
            <path
              d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
            /></svg
        ></a>
      </h3>
      <p>
        Эти крестики нолики, я написал как проект для изучения vue.js. В них нет
        ничего особенного, просто обычная игра, вроде даже без багов.
      </p>
      <h3>
        <a href="https://github.com/Rupikz/nigario"
          >nigario<svg
            class="svg-link"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 24 24"
          >
            <path
              d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
            /></svg
        ></a>
      </h3>
      <p>
        Эта мини игра была написана, чтобы попробовать себе в node.js. В ней
        куча багов, которые исправлять не буду.
      </p>
      <h2>Other projects</h2>
      <h3>
        <a href="https://github.com/Rupikz/knigovorot"
          >knigovorot<svg
            class="svg-link"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 24 24"
          >
            <path
              d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"
            /></svg
        ></a>
      </h3>
      <p>
        Данный сайт разрабатывался для диплома. Он не супер крутой, и отчасти
        даже не рабочий, но этого хватило, чтобы за него я получил пятерку. Если
        интересно, то темой диплома было сделать сервис по продаже и обмену
        книг.
      </p>
      <img class="gif" src="~@/assets/gifs/flowers.gif" alt="Nature" />
    </div>
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'

export default {
  name: 'Projects',
  components: { Navbar },
  data: () => ({}),
  created() {},
  mounted() {}
}
</script>

<style lang="scss" scoped>
@import '@/styles/_var.scss';

.container {
  max-width: 640px;
  width: 100%;

  .header {
    margin: 40px auto;
  }

  .description {
    a {
      color: inherit;
    }
    .svg-link {
      fill: $near;
      vertical-align: super;
      margin: 0 5px;
    }

    .gif {
      margin: 10px 0;
      width: 100%;
    }
  }
}
</style>
