<template>
  <div class="container">
    <div class="header">
      <Navbar />
    </div>
    <div class="description">
      <h2>About Me</h2>
      <p>
        I am Nikolau from Russia. I like coding, making websites and other tech
        stuff. I know a little English, so дальше буду писать на русском.
      </p>
      <p>
        Я не дизайнер, но на этом небольшом сайтике решил скреативничать. Если
        понравился ставьте лайки мне на страничке вконтакте.
      </p>
      <p>
        Из дополнительного, интересуюсь современной русской поэзией. Если
        поименно, это: Александр Бакулин и Вячеслав Машнов.
      </p>

      <h2>Tech stack</h2>
      <p>
        Как такового основного направления у меня нет, везде понемногу. Могу
        что-нибудь сверстать или скодить на js. Даже мини игру на питоне для
        майнкрафта писал. В колледже создавал сайтики себе и одногруппникам на
        php и mysql.
      </p>
      <p>
        В общем - пытаюсь что-то изучать и не стоять на месте. Сейчас пробую
        себя во фронтенде, кстати данный сайтик написан на vue.js. Зачем? - не
        знаю 🤷‍♂️
      </p>
      <p>На бекенде использую typescript и nest.js.</p>
      <img class="gif" src="~@/assets/gifs/river.gif" alt="Nature" />
    </div>
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'

export default {
  name: 'About',
  components: { Navbar }
}
</script>

<style lang="scss" scoped>
.container {
  max-width: 640px;
  width: 100%;

  .header {
    margin: 40px auto;
  }

  .gif {
    margin: 10px 0;
    width: 100%;
  }
}
</style>

<style lang="scss">
@import '@/styles/_var.scss';

.description {
  text-align: left;
  line-height: normal;
  padding: 0 40px;
  margin-bottom: 25%;

  p {
    padding: 10px 0;
    margin: 5px 0;
    white-space: pre-line;
    color: $description;
  }
}

h2,
h3 {
  text-align: left;
}

h2 {
  font-size: 36px;
  margin: 20px 0;
}

h3 {
  font-size: 24px;
  color: $text-header-less;
  margin: 25px 0 10px 0;
}
</style>
