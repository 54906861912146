<template>
  <section class="home-page">
    <div class="container">
      <img class="gif" src="~@/assets/gifs/rain.gif" alt="Nature" />
      <h1>RupikZ</h1>
      <span class="near">// Fullstack Developer //</span>
      <Navbar />
      <Social />
    </div>
  </section>
</template>

<script>
import Navbar from '../components/Navbar.vue'
import Social from '../components/Social.vue'

export default {
  name: 'Home',
  components: {
    Navbar,
    Social
  },
  data: () => ({}),
  created() {},
  mounted() {}
}
</script>

<style lang="scss" scoped>
@import '@/styles/_var.scss';

.gif {
  position: absolute;
  width: 150%;
  z-index: -1;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-150%, -50%);
}
.container {
  margin: 0;
  position: absolute;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  top: 50%;

  .near {
    color: $near;
  }

  h1 {
    font-size: 52px;
  }
}
</style>

<style lang="scss">
@import '@/styles/_var.scss';

h1,
h2,
h3 {
  font-family: $font-header;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  text-shadow: 2px 2px $text-shadow;
}

h2 {
  text-shadow: 1px 1px $text-shadow;
}

.container {
  text-align: center;
  margin: 0 auto;
}
</style>
