<template>
  <nav>
    <router-link to="/">Home</router-link>
    <router-link to="/about">About</router-link>
    <router-link to="/projects">Projects</router-link>
  </nav>
</template>

<script>
export default {
  name: 'Navbar'
}
</script>

<style lang="scss">
@import '@/styles/fonts.scss';
@import '@/styles/_var.scss';

nav {
  margin: 15px 0;

  a {
    font-family: $font-header;
    color: $text-header;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0 15px;
  }

  a:hover {
    color: $text-hover;
  }
}
</style>
